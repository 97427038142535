
































import {Component, Vue} from 'vue-property-decorator'
import {apiDelPlugin, apiGetPluginsBySoftwareId} from "@/api/setting/plugins";

@Component
export default class DevicePlugins extends Vue {
  soft_id: any = ''
  tableData: any = []
  parentName: any = ''

  newPlugins() {
    this.$router.push({
      path: '/setting/imagetru3d/device_plugin_edit',
      query: {
        id: this.soft_id,
      }
    })
  }

  delPlugin(row: any) {
    this.$confirm('确定删除？').then(async () => {
      const id = row.id.toString()
      await apiDelPlugin(id)
      await this.getPlugins()
    })

  }

  editPlugin(row: any) {
    this.$router.push({
      path: '/setting/imagetru3d/device_plugin_edit',
      query: {
        pluginId: row.id,
        parentName: this.parentName
      }
    })
  }

  async getPlugins() {
    apiGetPluginsBySoftwareId({soft_id: this.soft_id}).then((res) => {
      this.tableData = res
    })
  }

  created() {
    this.soft_id = Number(this.$route.query.id)
    this.getPlugins()
    this.parentName = this.$route.query.name
  }
}
